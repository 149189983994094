<template>
  <div class="container">
    <img src="/static/img/logo.png?v=2" class="logo" alt="Logo" />
    <div class="info">
      <p>电话：{{phone}}</p>
      <p>手机：{{mobile}}</p>
      <p>地址：{{address}}</p>
      <p>备案：{{copyright}}</p>
    </div>
    <img :src="qrcode" class="qrcode" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      mobile: "",
      phone: "",
      address: "",
      copyright: "",
      qrcode: "",
    };
  },
  created() {
    this.$api("/official/page/foot").then(({ data }) => {
      this.mobile = data.contact_mobile;
      this.phone = data.contact_phone;
      this.address = data.contact_address;
      this.copyright = data.copyright;
      this.qrcode = data.official_account_qrcode;
    });
  },
};
</script>
<style lang="scss" scoped>
.container {
  background: #534e4e;
  display: flex;
  // justify-content: center;
  align-items: center;
  padding: 0 240px;
  height: 180px;

  .logo {
    width: 113px;
    height: 38px;
    flex-shrink: 0;
  }

  .info {
    margin-left: 67px;
    color: $font-color-white;
    flex: 1;
    color: #dcdcdc;

    p {
      margin: 6px 0;
      font-size: $font-size-14;
    }
  }

  .qrcode {
    flex-shrink: 0;
    width: 84px;
    height: 84px;

    border-radius: 7px;
  }
}
</style>